import React from "react";
import { connect, useSelector } from "react-redux";
import { updateSlideTemplates, saveCurrentPageTemplates } from "../../store/storyline/actions";
import { UnControlled as CodeMirror } from "react-codemirror2"
import "codemirror/mode/jsx/jsx";
import "codemirror/mode/css/css";
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/fold/foldgutter.css';
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import { AppBar, Button, PersistentTabPanel, Tab, Tabs, Toolbar } from "../../shared/components";
import { RootState } from "../../store";
import { TemplateDefinition } from "../../store/storyline/types";


interface TemplatesProps {
    currentPageTemplate: string,
    updateSlideTemplates: typeof updateSlideTemplates,
    saveCurrentPageTemplates: typeof saveCurrentPageTemplates,
}

function shortenTemplateName(templateName: string) {
    return templateName.match(/((?:[^\/]+\/){0,1}[^\/]+)$/)?.[1] ?? templateName;
}

function Templates(props: TemplatesProps) {
    const { currentPageTemplate, updateSlideTemplates, saveCurrentPageTemplates } = props;
    const templates = useSelector((root: RootState) => root.storyline.templates);
    const [selectedTabValue, setSelectedTabValue] = React.useState(currentPageTemplate);

    const handleTabChange = React.useCallback((_event: React.ChangeEvent<{}>, newValue: string) => {
        setSelectedTabValue(newValue);
    }, [setSelectedTabValue]);

    return (
        <div className="tabs-container">

                <Tabs
                    style={{ backgroundColor: "var(--colors-blue-1)"}}
                    value={selectedTabValue}
                    onChange={handleTabChange}
                >
                {
                    Object.keys(templates).map(templateName => <Tab label={shortenTemplateName(templateName)} value={templateName} wrapped title={templateName} />)
                    }
                </Tabs>

            {
                Object.entries(templates).map(([templateName, template]) =>
                    <PersistentTabPanel key={templateName} selectedValue={selectedTabValue} value={templateName}>
                        <Template templateName={templateName} templates={templates} updateSlideTemplates={updateSlideTemplates} saveCurrentPageTemplates={saveCurrentPageTemplates} />
                    </PersistentTabPanel>
                )
            }
        </div>
    );
};

interface TemplateProps {
    templateName: string,
    templates: Record<string, TemplateDefinition>,
    updateSlideTemplates: typeof updateSlideTemplates,
    saveCurrentPageTemplates: typeof saveCurrentPageTemplates,
}

function Template(props: TemplateProps) {
    const { templateName, templates, updateSlideTemplates, saveCurrentPageTemplates } = props;
    const template = useSelector((root: RootState) => root.storyline.templates[templateName]);
    const [editor, setEditor] = React.useState(null);
    const [cssEditor, setCssEditor] = React.useState(null);

    const applyTemplateChange = () => {
        updateSlideTemplates({ [templateName]: { jsx: editor.doc.getValue(), css: cssEditor.doc.getValue() } });
    }

    const saveTemplate = () => {
        updateSlideTemplates({ [templateName]: { jsx: editor.doc.getValue(), css: cssEditor.doc.getValue() } });
        saveCurrentPageTemplates({ ...templates, [templateName]: { jsx: editor.doc.getValue(), css: cssEditor.doc.getValue() } });
    }

    return (
        <div className="fill">
            <div style={{ height: "60%", maxHeight: "60%" }}>
                <CodeMirror
                    className="fill"
                    value={template.jsx}
                    options={{
                        mode: "text/jsx",
                        lineNumbers: true,
                        indentUnit: 4,
                        theme: "material",
                        foldGutter: true,
                        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                    }}
                    editorDidMount={(editor) => {
                        setEditor(editor);
                        setTimeout(() => editor.execCommand("goDocStart"), 50);
                    }}
                />
            </div>
            <div style={{ height: "calc(40% - 64px)" }}>
                <div className="css-heading">CSS</div>
                <div style={{ height: "calc(100% - 20px)" }}>
                    <CodeMirror
                        className="fill"
                        value={template.css || "// Custom CSS goes here..."}
                        options={{
                            mode: "text/css",
                            lineNumbers: true,
                            indentUnit: 4,
                            theme: "material",
                            foldGutter: true,
                            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
                        }}
                        editorDidMount={(editor) => {
                            setCssEditor(editor);
                            setTimeout(() => editor.execCommand("goDocStart"), 50);
                        }}
                    />
                </div>
            </div>

            <AppBar className="action-panel" position="sticky">
                <Toolbar>
                    <Button color="secondary" variant="contained" onClick={applyTemplateChange}>Apply Changes</Button>
                    <Button color="primary" variant="contained" className="save-changes" onClick={saveTemplate}>Save Template</Button>
                </Toolbar>
            </AppBar>
        </div >
    );

}

export default connect(
    null,
    { updateSlideTemplates, saveCurrentPageTemplates })(Templates);