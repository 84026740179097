import * as metastoreApi from "../../shared/metastore-api-client";
import * as _ from "lodash";
import {
    LOAD_STORYLINE,
    SHOW_STORYLINE,
    GO_TO_X_Y_Z,
    GO_TO_ID,
    PARAMETER_VALUE_UPDATED,
    DATASOURCE_UPDATED,
    UPDATE_CURRENT_SLIDE_TEMPLATE,
    UPDATE_SLIDE_TEMPLATES,
    UPDATE_CURRENT_FRAME_DATA,
    UPDATE_CANVAS_STATE,
    StorylineState,
    NavigationTarget,
    ADD_GLOBAL_DATASOURCE,
    UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE,
    UpdateInFlightRequestsForDatasource,
    DataSource,
    ShowStorylineAction,
    LoadStorylineAction,
    Storyline,
    DatasourceUpdatedAction,
    TemplateDefinition
} from "./types";
import {
    TraceSource
} from "../tracing/types";
import { RootState } from "../index";
import { showError, showDetailedError } from "../notifications/actions";
import { addTraceEvent } from "../tracing/actions";
import { animateSlideNavigation } from "../../shared/services/slideTransitionAnimationService";
import { BehaviorSubject, Observable, merge } from "rxjs";
import { map, filter, debounceTime, groupBy, mergeAll, scan, pairwise } from 'rxjs/operators';
import { MapWithPathSupport, shouldRecordTraces } from "../../shared/utilities";
import { sendCanvasDataToVsCode, sendCssClassesToVsCode, sendStorylineStateToVsCode } from "../vscode/actions";
import { setPageError } from "../app/actions";
import * as api from "../../shared/api-client";
import { AppError } from "../app/types";
import { jsonParseReviver } from "../../shared/api-client-extensions";
import { compare } from "compare-versions";

interface ApiRequestEvent {
    datasource: DataSource;
}

interface ApiResponseEvent {
    datasource: DataSource;
    payload: unknown;
}

interface DatasourceStatusSummary {
    datasource: metastoreApi.Datasource;
    inFlightRequests: number;
    lastResponse: unknown;
}

interface DatasourceStatusDictionary {
    [key: string]: DatasourceStatusSummary;
}

let datasourcesPendingRefresh: DataSource[] = [];
const datasourceRefreshRequests$ = new BehaviorSubject<string>(null);
const apiRequests$ = new BehaviorSubject<ApiRequestEvent>(null);
const apiResponses$ = new BehaviorSubject<ApiResponseEvent>(null);
let datasourceStatuses$: Observable<DatasourceStatusDictionary>;

const datasourceCascadeMap = new Map<string, Array<string>>();

export function initializeDatasourceFetchPipeline() {
    return async (dispatch, getState: () => RootState) => {
        // Debounce datasource refresh requests to eliminate unnecessary API calls...
        datasourceRefreshRequests$
            .pipe(
                filter(datasourceId => datasourceId !== undefined && datasourceId !== null),
                groupBy(datasourceId => datasourceId),
                map((group: any) => group.pipe(
                    debounceTime(100)
                )),
                mergeAll()
            ).subscribe((datasourceId: string) => {
                const { datasources } = getState().storyline;
                const datasource = datasources.get(datasourceId);

                refreshDatasource(datasource, true)(dispatch, getState);
            });

        datasourceStatuses$ =
            merge(
                apiRequests$.pipe(filter(r => !!r), map(r => ({ "type": "REQUEST", ...r }))),
                apiResponses$.pipe(filter(r => !!r), map(r => ({ "type": "RESPONSE", ...r })))
            )
                .pipe(
                    scan((acc, elem) => {
                        switch (elem.type) {
                            case "REQUEST":
                                return (() => {
                                    const request = elem as ApiRequestEvent;
                                    var currentStatus = acc[request.datasource.name] || { inFlightRequests: 0, lastResponse: null };

                                    dispatch({
                                        type: UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE,
                                        datasource: request.datasource,
                                        inFlightRequestCount: currentStatus.inFlightRequests + 1
                                    } as UpdateInFlightRequestsForDatasource);

                                    return {
                                        ...acc,
                                        [request.datasource.name]: {
                                            datasource: request.datasource,
                                            inFlightRequests: currentStatus.inFlightRequests + 1
                                        }
                                    } as DatasourceStatusDictionary;
                                })();

                            case "RESPONSE":
                                return (() => {
                                    const response = elem as ApiResponseEvent;
                                    var currentStatus = acc[response.datasource.name];

                                    dispatch({
                                        type: UPDATE_INFLIGHT_REQUESTS_FOR_DATASOURCE,
                                        datasource: response.datasource,
                                        inFlightRequestCount: currentStatus.inFlightRequests - 1
                                    } as UpdateInFlightRequestsForDatasource);

                                    return {
                                        ...acc,
                                        [response.datasource.name]: {
                                            datasource: response.datasource,
                                            inFlightRequests: currentStatus.inFlightRequests - 1,
                                            lastResponse: response.payload
                                        }
                                    } as DatasourceStatusDictionary;
                                })();
                        }
                    }, {} as DatasourceStatusDictionary)
                );

        // Monitor data source event stream for final outstanding requests (per datasource) completing and
        // dispatch the DATASOURCE_UPDATED action if necessary...
        datasourceStatuses$
            .pipe(pairwise())
            .subscribe(([prevState, currentState]) => {
                for (let datasourceId of Object.keys(currentState)) {
                    const s0 = prevState[datasourceId];
                    const s1 = currentState[datasourceId];

                    // Only update the datasource value if the last in-flight request has just completed...
                    if (!s0 || s0.inFlightRequests !== 1 || s1.inFlightRequests !== 0) continue;

                    const hasCascadingInFlightCall = (datasourceId: string) => {
                        const cascadeSources = datasourceCascadeMap.get(datasourceId) || [];

                        if (cascadeSources && cascadeSources.length) {
                            return cascadeSources.find(hasCascadingInFlightCall);
                        }

                        return currentState[datasourceId].inFlightRequests > 0;
                    }

                    // If any of the cascading parent datasources have outstanding responses, don't bother updating the frame data with this (soon to be overwritten) result...
                    if (hasCascadingInFlightCall(datasourceId) && !_.isEmpty(getState().storyline.datasourceValues.get(datasourceId)?.[0])) continue;

                    shouldRecordTraces() && dispatch(addTraceEvent({
                        kind: "DatasourceRefreshed",
                        name: s0.datasource.name,
                        oldValue: getState().storyline.datasourceValues.get(datasourceId),
                        newValue: s1.lastResponse,
                        source: { kind: "ParameterValueChanged" } as TraceSource
                    }));

                    dispatch({
                        type: DATASOURCE_UPDATED,
                        datasourceName: datasourceId,
                        data: s1.lastResponse
                    } as DatasourceUpdatedAction);
                }
            });
    }
}

function fetchDatasourceData(datasource: DataSource, parameterValues: Map<string, any>, datasources: DataSource[]) {
    return async (dispatch, getState: () => RootState) => {
        if (!datasource.apiEndpoint) return null;

        // Only call the API if all the required parameters are populated...
        if (!_.chain(datasource.parameters).filter(p => p.isRequired).map(p => p.name).every(p => parameterValues.get(p) !== undefined && parameterValues.get(p) !== null).value())
            return null;

        const { user } = getState().app;

        // Get the parameter values and construct the parameter object for the request body...
        let parameterObject = _.chain(datasource.parameters)
            .map(p => ({ key: p.name, value: parameterValues.get(p.name) ?? p.defaultValue }))
            .filter(p => p.value !== null && p.value !== undefined)
            .reduce((acc, elem) => { acc[elem.key] = elem.value; return acc; }, {})
            .value();

            const appState = getState().app;
            const { metastoreUrl, dynamicDataApiUrl, maxDatasourceRetryCount } = appState.settings;
            const activeBranch = appState.activeBranch;

        apiRequests$.next({ datasource });
        const result = await new metastoreApi.DatasourcesClient(metastoreUrl, activeBranch, maxDatasourceRetryCount).fetchData(dynamicDataApiUrl, (datasource.apiEndpoint ?? []).join("/"), parameterObject).catch(ex => {
            const getError = (ex): AppError => {
                if (ex.status == null)
                    return { type: "Connectivity", message: `Your browser cannot reach the Qerent Data Server.`, details: `Unable to contact the server at \`${metastoreUrl}\`.\n\nPlease ensure you are connected to your corporate network or VPN.\n\nIf the issue persists, please contact the site administrator.` };

                if (ex.status === 404)
                    return { type: "NotFound", message: "Datasource not found.", details: `Please refresh this page and try again.  If the issue persists, please contact the site administrator.` };

                if (ex.status === 429)
                    return { type: "Connectivity", message: "Rate limit exceeded.", details: `Too many requests were submitted within the last minute.\n\nPlease wait a moment and try again.` };

                return { type: "Generic", message: "Failed to fetch page data.", details: user.isDeveloper ? ex.response : "Please check the filter values and try again." };
            };

            const error = getError(ex);
            dispatch(showDetailedError(error.message, error.details));

            return null;
        });
        apiResponses$.next({ datasource, payload: result });
        
        const storyline = getState().storyline;
        // Do not update parameter values during the initial load.  Data sources loaded via this mechanism are discarded at that point...
        if (!storyline?.loading && result != null) {
            const affectedSlots = storyline?.storyline?.chapters?.flatMap?.(c =>
                c.pages.flatMap(p =>
                    p.paragraphs.flatMap(par =>
                        par.slots
                    )
                )
            )?.filter(slot => slot.dataSource === datasource.name) ?? [];

            const allParameters = _.chain(datasources).flatMap(ds => ds.parameters).map(p => p.name).uniq().value();
            const affectedParameters = _.intersection(affectedSlots.map(s => s.name), allParameters);
            for (let affectedParameter of affectedParameters) {
                await updateParameterValue(affectedParameter, result, false, false, datasource.name)(dispatch, getState);
            }
        }

        return result;
    }
}

function extractDatasourcesFromObject(object: object): DataSource[] {
    if (!object) return [];
    return Object.entries(object).map(([key, value]: [string, metastoreApi.Datasource]) => ({
        name: key,
        autoRefresh: value?.autoRefresh ?? true,
        parameters: [
            ...value?.requiredParameters?.map(p => ({ ...p, isRequired: true })) ?? [],
            ...value?.optionalParameters?.map(p => ({ ...p, isRequired: false })) ?? []
        ],
        defaultData: JSON.parse(value?.defaultData ?? "null", jsonParseReviver),
        apiEndpoint: value?.apiEndpoint
    } as DataSource));
}

function mapDatasourcesForObject(object: object): { [name: string]: DataSource } {
    const datasources = extractDatasourcesFromObject(object);
    return Object.fromEntries(datasources.map(ds => [ds.name, ds]));
}

function* getAllDatasources(storyline: metastoreApi.Storyline) {
    yield* extractDatasourcesFromObject(storyline.dataSources);

    for (let chapter of storyline.chapters) {
        yield* extractDatasourcesFromObject(chapter.dataSources);

        for (let page of chapter.pages) {
            yield* extractDatasourcesFromObject(page.dataSources);

            for (let paragraph of page.paragraphs) {
                yield* extractDatasourcesFromObject(paragraph.dataSources);
            }
        }
    }
}

function mapApiStorylineToStateModel(storyline: metastoreApi.Storyline): Storyline {
    // Backwards compatibility for older version of Metastore.
    // @todo: Remove this once all Metastore instances are updated.
    let templates: Record<string, TemplateDefinition> = storyline.templates ??
        Object.fromEntries(
            _.chain(storyline.chapters)
                .flatMap(c => c.pages.map(p => p.template))
                .uniqBy((t: metastoreApi.Template) => t.name)
                .map((t: metastoreApi.Template) => [t.name, t.root])
                .value()
        );

    return {
        ...storyline,
        templates,
        dataSources: mapDatasourcesForObject(storyline),
        chapters: storyline.chapters.map(chapter => ({
            ...chapter,
            dataSources: mapDatasourcesForObject(chapter),
            pages: chapter.pages.map(page => ({
                ...page,
                template: typeof page.template == "string" ? page.template : page.template?.name, // Backwards compatibility for older version of Metastore.  @todo: Remove this once all Metastore instances are updated.
                dataSources: mapDatasourcesForObject(page),
                paragraphs: page.paragraphs.map(paragraph => ({
                    ...paragraph,
                    dataSources: mapDatasourcesForObject(paragraph)
                }))
            }))
        }))
    } as Storyline;
}

export function loadStoryline(id: string, parameterValues: Map<string, any>) {
    return async (dispatch, getState: () => RootState) => {
        dispatch({
            type: LOAD_STORYLINE,
            id
        } as LoadStorylineAction);

        datasourceCascadeMap.clear();

        const appState = getState().app;
        const metastoreUrl = appState.settings.metastoreUrl;
        const activeBranch = appState.activeBranch;

        const storyline = await new metastoreApi.StorylinesClient(metastoreUrl, activeBranch).getById(id).catch(ex => {
            const getError = (ex): AppError => {
                if (ex.status == null)
                    return { type: "Connectivity", message: `Your browser cannot reach the Qerent Data Server.`, details: `Unable to contact the server at \`${metastoreUrl}\`.\n\nPlease ensure you are connected to your corporate network or VPN.\n\nIf the issue persists, please contact the site administrator.` };

                if (ex.status === 404)
                    return { type: "NotFound", message: "Storyline not found.", details: `Please check the address and try again.\n\nIf you believe this is an error, please contact the site administrator.` };

                if (ex.status === 429)
                    return { type: "Connectivity", message: "Rate limit exceeded.", details: `Too many requests were submitted within the last minute.\n\nPlease wait a moment and try again.` };

                return { type: "Generic", message: "Failed to load storyline from the Qerent Data Server.", details: ex.response };
            };

            dispatch(setPageError(getError(ex)));
            return;
        });

        if (!storyline) {
            return;
        }

        let datasources = [...getAllDatasources(storyline)];
        let datasourceMap = new Map(datasources.map(ds => [ds.name, ds]));

        let datasourceValues = await Promise.all(datasources.map(async datasource =>
            datasource.defaultData ?
                [datasource.name, datasource.defaultData] as [string, any] :
                [datasource.name, await fetchDatasourceData(datasource, parameterValues, datasources)(dispatch, getState)] as [string, any]
        ));

        // User has already navigated to a different storyline - discard the results and move on...
        if (getState().storyline.id !== id) {
            return;
        }

        const mappedStoryline = mapApiStorylineToStateModel(storyline);

        dispatch({
            type: SHOW_STORYLINE,
            id,
            storyline: mappedStoryline,
            dataSources: datasourceMap,
            dataSourceValues: new Map(datasourceValues),
            parameterValues
        } as ShowStorylineAction);

        // Set the unbound parameter values that are found in the initial data sources, refreshing affected data sources as we go along...
        const allParameters = _.chain(datasources).flatMap(ds => ds.parameters).map(p => p.name).uniq().value();
        const unboundParameters = _.difference(allParameters, ...parameterValues.keys());

        const datasourceValueDictionary = Object.fromEntries(datasourceValues);
        const slotsInFirstParagraph = mappedStoryline?.chapters?.[0]?.pages?.[0]?.paragraphs?.[0]?.slots;
        const parametersToPopulate = _.intersection(slotsInFirstParagraph.map(s => s.name), unboundParameters);

        slotsInFirstParagraph
            .filter(slot => parametersToPopulate.includes(slot.name))
            .forEach(slot => {
                updateParameterValue(slot.name, datasourceValueDictionary[slot.dataSource])(dispatch, getState);
            });

        setTimeout(() => {
            dispatch(sendStorylineStateToVsCode())
            dispatch(sendCanvasDataToVsCode());
            sendCssClassesToVsCode();
        }, 2000);
    }
}

export function updateParameterValue(parameterName: string, newValue: any, isInitialLoad = false, preventDatasourceRefresh = false, sourceDatasourceId: string = null) {
    return async (dispatch, getState: () => RootState) => {
        if (!parameterName) return;

        // Short-circuit action if the value hasn't actually changed...
        const oldValue = getState()?.storyline?.parameterValues?.get(parameterName);
        if (_.isEqual(oldValue, newValue)) {
            return;
        }

        shouldRecordTraces() && dispatch(addTraceEvent({
            kind: "ParameterValueChanged",
            name: parameterName,
            oldValue,
            newValue,
            source: { kind: isInitialLoad ? "InitialLoad" : sourceDatasourceId ? "DatasourceBinding" : "InputControlChange" } as TraceSource
        }));

        dispatch({
            type: PARAMETER_VALUE_UPDATED,
            parameterName,
            newValue
        });

        // Datasources will only reference the root parameter of this (possible nested) path...
        const rootParameterName = MapWithPathSupport.getBaseParameterName(parameterName);

        // Find the data sources that are affected by the parameter change...
        let affectedDatasources = _.filter(Array.from(getState().storyline.datasources.values()), (ds: DataSource) => !!_.find(ds.parameters, p => p.name === rootParameterName));

        // Record the datasource that triggered this cascading update...
        if (sourceDatasourceId) {
            affectedDatasources.forEach(ds => {
                datasourceCascadeMap.set(ds.name, _.uniq(_.filter([...datasourceCascadeMap.get(ds.name) || [], sourceDatasourceId], a => !!a)));
            });
        }

        // Keep track of the data sources that now have outstanding updates pending, so that we can refresh them on the next manual update run...
        let dataSourcesToManuallyRefresh = _.filter(affectedDatasources, ds => !ds.autoRefresh);
        datasourcesPendingRefresh = _.unionBy(datasourcesPendingRefresh, dataSourcesToManuallyRefresh, a => a.name);

        if (!preventDatasourceRefresh) {
            // Post the list of data sources to update to the RxJS subject, so that datasource updates can be grouped and debounced...
            let datasourcesToAutoRefresh = _.filter(affectedDatasources, ds => isInitialLoad || ds.autoRefresh);
            for (let datasource of datasourcesToAutoRefresh) {
                datasourceRefreshRequests$.next(datasource.name);
            }
        }

        setTimeout(() => dispatch(sendCanvasDataToVsCode()), 500);
    };
}

export function setDefaultParameterValue(parameterName: string, newValue: any) {
    return async (dispatch, getState: () => RootState) => {
        if (!parameterName) return;

        // Short-circuit action if the parameter already has a value...
        const existingValue = getState()?.storyline?.parameterValues?.get(parameterName);
        if (existingValue === null || existingValue === undefined) {
            updateParameterValue(parameterName, newValue)(dispatch, getState);
        }
    };
}

export function refreshDatasourceByName(name: string, _additionalInputParameters = {}, _mapResultToDatasourceParameters = false) {
    const additionalParameters = _additionalInputParameters ?? {};
    const mapResultToDatasourceParameters = _additionalInputParameters === true ? true : (_mapResultToDatasourceParameters ?? false); // Backwards compatibility for prior version, where "mapResultToDatasourceParameters" was the second parameter..."

    return async (dispatch, getState: () => RootState) => {
        const datasource = _.find(Array.from(getState().storyline.datasources).map(([_, value]) => value), a => a.name === name);
        if (datasource) {
            refreshDatasource(datasource, mapResultToDatasourceParameters, additionalParameters)(dispatch, getState);
        }
    };
}

export function refreshDatasource(datasource: DataSource, mapResultToDatasourceParameters = false, additionalParameters = {}) {
    return async (dispatch, getState: () => RootState) => {
        if (!datasource) return;

        // If additional parameters are provided, augment the canvas parameter values with these...
        const parameterValues = _.isEmpty(additionalParameters) ?
            getState().storyline.parameterValues :
            new Map<string, any>([
                ...getState().storyline.parameterValues.entries(),
                ...Object.entries(additionalParameters)
            ]);

        // Only call the API if all the required parameters are populated...
        if (_.chain(datasource.parameters).filter(p => p.isRequired).map(p => p.name).every(p => parameterValues.get(p) !== undefined && parameterValues.get(p) !== null).value()) {
            const datasourcesToMapParametersTo = mapResultToDatasourceParameters ? Array.from(getState().storyline.datasources).map(([_, value]) => value) : [];

            await fetchDatasourceData(datasource, parameterValues, datasourcesToMapParametersTo)(dispatch, getState);
        }

        setTimeout(() => dispatch(sendCanvasDataToVsCode()), 500);
    };
}

export function fetchDatasourceByName(name: string, _additionalInputParameters = {}) {
    const additionalParameters = _additionalInputParameters ?? {};

    return async (_dispatch, getState: () => RootState) => {
        const datasource = _.find(Array.from(getState().storyline.datasources).map(([_, value]) => value), a => a.name === name);

        if (!datasource)
            throw new Error(`Datasource with name '${name}' not found in the current storyline.`);

        // If additional parameters are provided, augment the canvas parameter values with these...
        const parameterValues = _.isEmpty(additionalParameters) ?
            getState().storyline.parameterValues :
            new Map<string, any>([
                ...getState().storyline.parameterValues.entries(),
                ...Object.entries(additionalParameters)
            ]);

        const missingInputParameters = _.chain(datasource.parameters).filter(p => p.isRequired).map(p => p.name).filter(p => parameterValues.get(p) === undefined || parameterValues.get(p) === null).value();
        if (missingInputParameters.length > 0)
            throw new Error(`The following required parameters are missing values: ${missingInputParameters.join(", ")}`);

        // Get the parameter values and construct the parameter object for the request body...
        const inputParameters = Object.fromEntries(
            datasource.parameters
                .map(p => [p.name, parameterValues.get(p.name)])
                .filter(([_key, value]) => value !== null && value !== undefined)
        );

        const appState = getState().app;
        const { metastoreUrl, dynamicDataApiUrl } = appState.settings;
        const activeBranch = appState.activeBranch;
        return new metastoreApi.DatasourcesClient(metastoreUrl, activeBranch).fetchData(dynamicDataApiUrl, (datasource.apiEndpoint ?? []).join("/"), inputParameters);
    };
};

export function applyParameterValueChanges(mapResultToDatasourceParameters = false) {
    return async (dispatch, getState: () => RootState) => {
        // Make a copy of the datasourcesPendingRefresh array and clear out the original to prevent race conditions/duplicate API calls on subsequent function invocations...
        const snapshotDatasourcesPendingRefresh = [...datasourcesPendingRefresh];
        datasourcesPendingRefresh = [];

        await Promise.all(_.map(snapshotDatasourcesPendingRefresh, async ds => await refreshDatasource(ds, mapResultToDatasourceParameters)(dispatch, getState)));
    }
}

type CoordinateCalculators = {
    [key: string]: (state: StorylineState) => NavigationTarget
}

const coordinateCalculators: CoordinateCalculators = {
    "NEXT_PARAGRAPH": (state) => {
        if (state.paragraphIndex < state.canvases[state.chapterIndex][state.pageIndex].length - 1) {
            return {
                chapterIndex: state.chapterIndex,
                pageIndex: state.pageIndex,
                paragraphIndex: state.paragraphIndex + 1,
                previousFrameIndex: state.paragraphIndex
            };
        }
        else if (state.pageIndex < state.canvases[state.chapterIndex].length - 1) {
            return {
                chapterIndex: state.chapterIndex,
                pageIndex: state.pageIndex + 1,
                paragraphIndex: 0
            }
        }
        else if (state.chapterIndex < state.canvases.length - 1) {
            return {
                chapterIndex: state.chapterIndex + 1,
                pageIndex: 0,
                paragraphIndex: 0
            }
        }
    },
    "PREVIOUS_PARAGRAPH": (state) => {
        if (state.paragraphIndex > 0) {
            const paragraphIndex = state.paragraphIndex - 1;

            return {
                chapterIndex: state.chapterIndex,
                pageIndex: state.pageIndex,
                paragraphIndex,
                previousFrameIndex: state.paragraphIndex
            };
        }
        else if (state.pageIndex > 0) {
            const pageIndex = state.pageIndex - 1;
            const paragraphIndex = state.canvases[state.chapterIndex][pageIndex].length - 1;

            return {
                chapterIndex: state.chapterIndex,
                pageIndex,
                paragraphIndex
            }
        }
        else if (state.chapterIndex > 0) {
            const chapterIndex = state.chapterIndex - 1;
            const pageIndex = state.canvases[chapterIndex].length - 1;
            const paragraphIndex = state.canvases[chapterIndex][pageIndex].length - 1;

            return {
                chapterIndex,
                pageIndex,
                paragraphIndex
            }
        }
    },
    "NEXT_PAGE": (state) => {
        if (state.pageIndex < state.canvases[state.chapterIndex].length - 1) {
            return {
                chapterIndex: state.chapterIndex,
                pageIndex: state.pageIndex + 1,
                paragraphIndex: 0
            }
        }
        else if (state.chapterIndex < state.canvases.length - 1) {
            return {
                chapterIndex: state.chapterIndex + 1,
                pageIndex: 0,
                paragraphIndex: 0
            }
        }
    },
    "PREVIOUS_PAGE": (state) => {
        if (state.pageIndex > 0) {
            return {
                chapterIndex: state.chapterIndex,
                pageIndex: state.pageIndex - 1,
                paragraphIndex: 0
            }
        }
        else if (state.chapterIndex > 0) {
            const chapterIndex = state.chapterIndex - 1;
            const pageIndex = state.canvases[chapterIndex].length - 1;

            return {
                chapterIndex,
                pageIndex,
                paragraphIndex: 0
            }
        }
    },
    "NEXT_CHAPTER": (state) => {
        if (state.chapterIndex < state.canvases.length - 1) {
            return {
                chapterIndex: state.chapterIndex + 1,
                pageIndex: 0,
                paragraphIndex: 0
            }
        }
    },
    "PREVIOUS_CHAPTER": (state) => {
        if (state.chapterIndex > 0) {
            return {
                chapterIndex: state.chapterIndex - 1,
                pageIndex: 0,
                paragraphIndex: 0
            }
        }
    }
};

export function navigateTo(direction: "NEXT_PARAGRAPH" | "PREVIOUS_PARAGRAPH" | "NEXT_PAGE" | "PREVIOUS_PAGE" | "NEXT_CHAPTER" | "PREVIOUS_CHAPTER") {
    return (dispatch, getState: () => RootState) => {
        const storyline = getState()?.storyline;
        if (!storyline) return;

        const handler = coordinateCalculators[direction];
        const newCoordinates = handler(storyline);
        if (newCoordinates) {
            goToXYZ(newCoordinates.chapterIndex, newCoordinates.pageIndex, newCoordinates.paragraphIndex, newCoordinates.previousParagraphIndex)(dispatch, getState);
        }

        setTimeout(() => {
            dispatch(sendStorylineStateToVsCode());
        }, 500);
    }
}

export function goToXYZ(chapterIndex: number, pageIndex: number, paragraphIndex: number, previousParagraphIndex?: number) {
    return (dispatch, getState: () => RootState) => {
        const storyline = getState()?.storyline;
        if (!storyline) return;

        const animationDirection = chapterIndex > storyline.chapterIndex ? "right" :
            chapterIndex < storyline.chapterIndex ? "left" :
                pageIndex > storyline.pageIndex ? "down" :
                    pageIndex < storyline.pageIndex ? "up" :
                        null;

        animationDirection && animateSlideNavigation(animationDirection);

        dispatch({
            type: GO_TO_X_Y_Z,
            chapterIndex,
            pageIndex,
            paragraphIndex,
            previousParagraphIndex
        });

        setTimeout(() => {
            dispatch(sendStorylineStateToVsCode());
        }, 500);
    }
}

export function goToID(id: string) {
    return {
        type: GO_TO_ID,
        id
    }
}

export function updateCurrentSlideTemplate(newTemplate: string, newCustomCss: string) {
    setTimeout(sendCssClassesToVsCode, 2000);

    return {
        type: UPDATE_CURRENT_SLIDE_TEMPLATE,
        newTemplate,
        newCustomCss
    }
}

export function updateSlideTemplates(newTemplates: Record<string, TemplateDefinition>) {
    return {
        type: UPDATE_SLIDE_TEMPLATES,
        newTemplates
    }
}

export function updateCurrentFrameData(newData: any) {
    return {
        type: UPDATE_CURRENT_FRAME_DATA,
        newData
    }
}

export function updateCurrentCanvasState(stateChunk: Object) {
    return (dispatch, getState: () => RootState) => {
        const currentState = getState()?.storyline.canvasState;
        // Short-circuit if the values are unchanged...
        if (_.isMatch(currentState, stateChunk)) return;

        const newState = { ...currentState, ...stateChunk };

        dispatch({
            type: UPDATE_CANVAS_STATE,
            newState
        });
    };
}

function getTemplateReferences(jsx: string): string[] {
    const regex = /<Template[^>]*path="([^"]*)"/g;
    return _.uniq(Array.from(jsx.matchAll(regex)).map(match => match[1]));
}

/// Build up hierarchical input model from flat template dictionary...
function getTemplateInputModel(templateName: string, templates: Record<string, TemplateDefinition>, currentHierarchy: string[] = []): metastoreApi.TemplateInputModel {
    const template = templates[templateName];
    // Short-circuit if template not found or a circular reference is detected...
    if (!template || currentHierarchy.includes(templateName)) {
        return undefined;
    }

    const templateReferences = getTemplateReferences(template.jsx);

    return {
        ...template,
        id: templateName,
        includes: templateReferences.map(tr => getTemplateInputModel(tr, templates, [...currentHierarchy, templateName]))
    };
}

export function saveCurrentPageTemplates(templates: Record<string, TemplateDefinition>) {
    return (dispatch, getState: () => RootState) => {
        const storylineState = getState().storyline;

        const appState = getState().app;
        const metastoreUrl = appState.settings.metastoreUrl;
        const activeBranch = appState.activeBranch;

        new metastoreApi.StorylinesClient(metastoreUrl, activeBranch)
            .updateTemplate(
                storylineState.id,
                getTemplateInputModel(storylineState.currentParagraph.templateName, templates)
            )
            .catch((error: api.ApiException) => {
                dispatch(showError(error.response));
            });
    }
}

export function setDatasourceData(datasourceName: string, data: Object) {
    return (dispatch, getState: () => RootState) => {
        const storyline = getState().storyline;
        const existingDatasource = [...storyline.datasources.values()].find(ds => ds.name === datasourceName);
        const datasourceId = existingDatasource?.name ?? datasourceName;

        if (!existingDatasource) {
            dispatch({
                type: ADD_GLOBAL_DATASOURCE,
                datasource: {
                    name: datasourceName,
                    defaultData: data,
                    parameters: []
                } as DataSource
            });
        }

        dispatch({
            type: DATASOURCE_UPDATED,
            datasourceName: datasourceId,
            data
        } as DatasourceUpdatedAction);
    }
}

export function patchDatasourceData(datasourceName: string, diff: Object) {
    return (dispatch, getState: () => RootState) => {
        const storyline = getState().storyline;
        const existingDatasource = [...storyline.datasources.values()].find(ds => ds.name === datasourceName);
        const datasourceId = existingDatasource?.name ?? datasourceName;

        if (!existingDatasource) {
            dispatch({
                type: ADD_GLOBAL_DATASOURCE,
                datasource: {
                    name: datasourceName,
                    defaultData: diff,
                    parameters: []
                } as DataSource
            });
        }

        const existingDatasourceData = storyline.datasourceValues.get(datasourceId) ?? {};

        dispatch({
            type: DATASOURCE_UPDATED,
            datasourceId,
            data: { ...existingDatasourceData, ...diff }
        });
    }
}