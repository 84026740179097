import { IconButton, Tooltip } from "@mui/material";
import { useAuth0 } from "../../auth/AuthContext";
import { Report } from "../../shared/components/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useSettings } from "../../shared/providers/SettingsProvider";

export function RequestAssistance() {
    const { user, userMetadata } = useAuth0();
    const storyline = useSelector((state: RootState) => state.storyline);
    const error = useSelector((state: RootState) => state.app.errors?.global ?? state.app.errors?.page);
    const settings = useSettings();

    if (!settings.features.requestSupport) return null;

    const subject = `Support Request: ${new URL(document.URL).host}`;
    const technicalDetails = [
        { heading: "URL", value: document.URL.toString() },
        { heading: "Page Title", value: document.title },
        { heading: "User Name", value: userMetadata.name },
        { heading: "User Email", value: userMetadata.email },
        { heading: "Browser", value: navigator.userAgent },
        { heading: "Window Dimensions", value: `${window.innerWidth}x${window.innerHeight}` },
        { heading: "Error Type", value: error?.type },
        { heading: "Error Message", value: error?.message },
        { heading: "Error Details", value: error?.details },
    ];
    const body = `Dear Qerent Support Team,

I require assistance with the following:
-----------------------------------------------------------------------------------------------------------------------------------




Please describe the nature of your issue or suggestion, as well as any steps that can be used to reproduce it




-----------------------------------------------------------------------------------------------------------------------------------

Technical Information:
-----------------------------------------------------------------------------------------------------------------------------------
${technicalDetails.filter(d => !!d.value).map(d => `${d.heading}: ${d.value}`).join("\n")}
-----------------------------------------------------------------------------------------------------------------------------------

Regards,
${user?.name}`;
    const link = `mailto:support@qerent.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    return (
        <Tooltip title="Request Assistance" arrow>
            <a href={link} target="_blank" rel="noopener noreferrer">
                <IconButton id="btn-feedback">
                    <Report size="small" />
                </IconButton>
            </a>
        </Tooltip>
    );
}